.Placeholder-module_placeholder-page_Nq8jL__Main {
  background-color: var(--bg-block);
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  display: flex;
}

.Placeholder-module_placeholder-page__text_NkB5W__Main {
  color: var(--text-primary);
  margin-top: 20px;
}

@media (max-width: 660px) {
  .Placeholder-module_placeholder-page_Nq8jL__Main {
    text-align: center;
    width: auto;
  }
}

